<template>
    <div class="page-container">
        <div class="page-top">
            <div class="pic-box">
                <img src="../../assets/productdetail/tmp-cover-pic2.png" alt="" class="nft-pic">
            </div>
            <div class="info-box">
                <div class="info-title-box">
                    <div class="info-title">《灯光佛影》</div>
                    <div class="btn-grp">
                        <div class="btn-fav">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav">
                            112
                        </div>
                        <img src="../../assets/productdetail/icon-share.png" alt="" class="icon-share">
                    </div>
                </div>
                <div class="fxl-buycount">
                    <img src="../../assets/productdetail/icon-fxl.png" alt="" class="icon-fxl">
                    <div class="fxl-count">300</div>
                    <div class="buy-count">已有 <span>56</span>人购买</div>
                </div>
                <div class="auth-info">
                    艺术家：王喜文 | 作品集：国风佛系数字绘画
                </div>
                <div class="price">¥ 49.9</div>
                <div class="btn-buy">立即购买</div>
                <div class="chain-info">
                    <div class="chain-head">链上信息</div>
                    <div class="chain-block">
                        <div class="chain-info-item">合约地址：0x84FC3ea417Eb7cB229C6ce95843f68dAC59C0088</div>
                        <div class="chain-info-item">合约类型：ERC-1155</div>
                        <div class="chain-info-item">区块链：文昌链</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-bottom">
            <!-- 作者信息 -->
            <div class="auth-block">
                <div class="pic-name">
                    <img src="../../assets/productdetail/icon-author.png" alt="" class="auth-pic">
                    <div class="auth-name">王喜文</div>
                </div>
                <div class="auth-detail">王喜文，数字艺术家，数字经济专家，工学博士，情报学博士后。自幼学习国画，所画风格多样。2009~2018 年在国家工信部直属单位工作，历任电子商务研究所所长、工业 4.0 研究所所长。现为九三学社中央科技委委员、九三学社中央促进技术创新工作委员会委员。出版专著有《区块链+5G》、《智能+》等系列丛书共计 50 多本。</div>
            </div>
            <div class="page-title">
                <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-left">
                <div>藏品描述</div>
                <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-right">
            </div>
            <div class="desc-list">
                <div class="desc-item">
                    <div class="desc-item-head">藏品背景</div>
                    <div class="desc-item-content">国风佛系作品，兼具国画、动漫等艺术形式，造境无语，又形神兼备；法相庄严，且寓意有道。得见国风佛系作品，心灵得以澄净，心智得以开启。</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head">藏品介绍</div>
                    <div class="desc-item-content">第一期发行200份 《灯光佛影》数字藏品。莲烛光影幢幢，佛像年代久远。佛门生涯孤寂，所谓青灯古佛伴一生，不负如来不负卿。灯影与佛影两相映衬，禅心
澄净，忘怀世俗，沉滞于时空长河。</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head">权益说明</div>
                    <div class="desc-item-content">数字藏品可以通俗理解为区块链凭证。数字藏品为虚拟数字商品，特性为不可分割、不可替代、独一无二。数字藏品常见于文化艺术品领域、知识产权的链上发行、流
转、确权等作用，能有效保护链上知识产权，防止篡改、造假等，是区块链技术的一类场景应用。</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>